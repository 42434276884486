import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { DATE_FORMATS } from '../../../../../utils/constants';

// Component(s)
import Accordion from '../shared/custom/Accordion';
import AccordionRow from '../shared/AccordionRow';
import PassportPhoto from '../shared/PassportPhoto';
import PncCheck from '../shared/PncCheck';

// Util(s)
import { DateTimeUtil, TargetBaggageUtil, TargetDocumentUtil, TargetPersonUtil } from '../../../../../utils';
import { toPncWarningTag } from '../shared/helper/adaptPncContent';

const AirpaxTargetingInformation = ({ persons }) => {
  const toPerson = (index, person) => {
    const document = TargetDocumentUtil.doc(person);
    const baggage = TargetBaggageUtil.get(person);
    const photoContent = TargetPersonUtil.additionalPhotoContent(person);

    return {
      heading: `${TargetPersonUtil.firstName(person)} ${TargetPersonUtil.lastName(person)} ${index !== 0 ? '(Associated passenger)' : ''}`,
      summary: toPncWarningTag(person),
      expanded: false,
      children: (
        <div>
          <dl className="govuk-summary-list">
            <AccordionRow
              label="Passport photo"
              value={<PassportPhoto photoContent={photoContent} />}
              id="passport-photo"
            />
            <AccordionRow
              label="Has this person been added to the border crossing?"
              value={TargetPersonUtil.watchList.added(person)}
              id="added-to-wi"
            />
            <AccordionRow
              label="Border crossing (IRN) Individual Reference Number"
              value={TargetPersonUtil.watchList.referenceNumber(person)}
              id="watchlist-reference"
            />
            <AccordionRow
              label="Nationality"
              value={TargetPersonUtil.nationality(person)}
              id="nationality"
            />
            <AccordionRow
              label="Date of birth"
              value={DateTimeUtil.format(TargetPersonUtil.dob(person), DATE_FORMATS.SHORT)}
              ID="date-of-birth"
            />
            <AccordionRow
              label="Sex"
              value={TargetPersonUtil.formattedGender(person)}
              id="sex"
            />
            <AccordionRow
              label="Travel document type"
              value={TargetDocumentUtil.docTypeDescription(document)}
              id="travel-document-type"
            />
            <AccordionRow
              label="Travel document number"
              value={TargetDocumentUtil.docNumber(document)}
              id="travel-document-number"
            />
            <AccordionRow
              label="Travel document expiry"
              value={DateTimeUtil.format(TargetDocumentUtil.docExpiry(document), DATE_FORMATS.SHORT)}
              id="travel-document-expiry"
            />
            <AccordionRow
              label="Passenger status"
              value={TargetPersonUtil.passengerStatus(person)}
              id="passenger-status"
            />
            <AccordionRow
              label="Seat number"
              value={TargetPersonUtil.seatNumber(person)}
              id="seat-number"
            />
            <AccordionRow
              label="Checked bags"
              value={TargetBaggageUtil.totalCheckedBags(baggage)}
              id="checked-bags"
            />
            <AccordionRow
              label="Total weight"
              value={TargetBaggageUtil.totalWeight(baggage)}
              id="total-weight"
            />
            <AccordionRow
              label="Tag numbers"
              value={TargetBaggageUtil.tags(baggage)}
              id="tag-numbers"
            />
          </dl>
          <PncCheck person={person} />
        </div>
      ),
    };
  };

  return (
    <div id="passengers-details" className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <Heading size="l">People</Heading>
        <Accordion
          className="targets-info-accordion"
          id="accordion-default"
          items={persons.map((person, index) => toPerson(index, person))}
        />
      </div>
    </div>
  );
};

AirpaxTargetingInformation.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.shape({})),
};

AirpaxTargetingInformation.defaultProps = {
  persons: [],
};

export default AirpaxTargetingInformation;
