// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import { Link } from 'react-router-dom';
import React from 'react';

// Config(s)
import { LOCAL_STORAGE_KEYS, PATHS, STRINGS, TAB_ID, FILTER_TAB_ID } from '../../utils/constants';
import DEFAULTS from '../../routes/Task/TaskList/constants'; // TaskList/constants';

// Context(s)
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTasks } from '../../context/TasksContext';
import { useView } from '../../context/ViewContext';

// Component
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

// Hook
import useSetTabTitle from '../../utils/Hooks/useSetTabTitle';

// Styling
import './TaskManagementHeader.scss';

const TaskManagementHeader = ({ headerLabel, size = 'xl', links = [], ...props }) => {
  const { hasRole, isLiveServicesUser } = usePermission();
  const { setSelectedTab, setTabIndex, setSelectedFilterTab } = useTabs();
  const { setSelectedForDelete, setRules } = useTasks();
  const { isTargetsPath, setIsSettingView, view } = useView();

  useSetTabTitle(headerLabel);

  const onClick = (label) => {
    if (![STRINGS.TASK_LINK_HEADERS.ADMIN, STRINGS.TASK_LINK_HEADERS.AIRPAX].includes(label)) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.VIEW_SWAP, label.toUpperCase());
    }

    setIsSettingView(true);
    setSelectedFilterTab(FILTER_TAB_ID.FILTERS);
    setTabIndex(0);
    setSelectedTab(TAB_ID.NEW);
    setSelectedForDelete([]);
    setRules(null);
    localStorage.removeItem(DEFAULTS[view].filters.key);
  };

  const toComponent = (link) => {
    if (!link?.show) {
      return null;
    }

    if (!hasRole(link?.rbac?.readRole)) {
      return null;
    }

    const constructClassNameFromLabels = ({ label }) => {
      return `task-link-${label.toLowerCase().split(' ').join('-')}`;
    };

    return (
      <Link
        key={link.url}
        className={constructClassNameFromLabels(link)}
        onClick={() => onClick(link?.label)}
        to={link.url}
      >
        {link.label}
      </Link>
    );
  };

  return (
    <div className="heading-container" {...props}>
      <Heading id="task-management-header" size={size}>
        {headerLabel}
      </Heading>
      { links.map(toComponent).filter((item) => !!item) }
      <ComponentWrapper show={isLiveServicesUser && !isTargetsPath}>
        <Link id="admin-panel-url" aria-label="admin" to={PATHS.ADMIN}>Admin</Link>
      </ComponentWrapper>
    </div>
  );
};

export default TaskManagementHeader;
