import { isEqual } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';

// Config
import config from '../../../../../../../utils/config';
import { CONTENT_TYPE, DATE_FORMATS } from '../../../../../../../utils/constants';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../context/TaskContext';

// Components(s)
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import DetailModal from './DetailModal';
import UpliftedForm from '../forms/UpliftedForm';

// Helpers
import deleteAdditionalContent from '../../../helper/deleteAdditionalContent';
import updateAdditionalContent from '../../../helper/updateAdditionalContent';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';
import { setTabTitle, TAB_TITLES } from '../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import { DateTimeUtil } from '../../../../../../../utils';
import DocumentUploader from '../../../../../../../utils/Form/ReactForm/Uplift/documentUploader';

// Form
import form from '../../../../../../../forms/uplift/editDetail';

// Styling
import './EditDetail.scss';

const EditDetail = ({ ...props }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const fileUploadApiClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [prefillData, setPrefillData] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { taskId, task, setTask, editorContentProps, additionalContent, setAdditionalContent } = useTask();
  const { offsetTop, data: content, onClose } = editorContentProps;

  setTabTitle(TAB_TITLES.EDIT_TASK_DETAIL);

  const checkAdditionalContentItemExists = (id) => {
    return additionalContent?.some((item) => item.content.id === id) || false;
  };

  const hasPhotograph = (data) => {
    return !!data?.photograph?.photo;
  };

  const hasContentPhotoChanged = (data) => {
    return !isEqual(data?.photograph?.photo, prefillData?.photograph?.originalPhoto);
  };

  /**
   * onChange required to hook into the form renderer onChange event to
   * remove required field data when photo has been changed or removed.
   */
  const onChange = async (data) => {
    const hasFormPhotographChanged = (formData) => {
      return !isEqual(formData?.photograph?.photo, formData?.photograph?.originalPhoto);
    };

    if (data.type === CONTENT_TYPE.TEXT) {
      setShowDeleteModal(data.content.trim() === '');
      return;
    }

    if (!hasFormPhotographChanged(data)) {
      return data;
    }

    setShowDeleteModal(!(data?.photograph?.photo));

    // Make originalPhoto to be the same as the newly attached photo.
    data.photograph.originalPhoto = data?.photograph?.photo;

    // Remove field values related to the attached photograph.
    delete data.photograph.source;
    delete data.photograph.approximateDateTaken;
    return data;
  };

  const onDelete = async () => {
    if (checkAdditionalContentItemExists(content.id)) {
      const updatedContent = additionalContent.filter((item) => item.content.id !== content.id);
      setTask(deleteAdditionalContent(task, content.id, !updatedContent || updatedContent.length === 0));
      setAdditionalContent(updatedContent);
      onClose();
    } else {
      await AxiosRequests.deleteContent(apiClient, taskId, content.id)
        .then((updatedTask) => {
          setTask(updatedTask);
          onClose();
        })
        .catch((exception) => console.error(exception.message));
    }
  };

  const removeOldPhoto = async () => {
    await AxiosRequests.deletePhoto(fileUploadApiClient, content.url);
  };

  const handleContentPhotoChange = async (data) => {
    await removeOldPhoto();
    return DocumentUploader.upload(fileUploadApiClient, taskId, data?.photograph?.photo?.file);
  };

  const toFile = async (url) => {
    const blob = await AxiosRequests.blobData(fileUploadApiClient, url);
    return {
      name: content.filename, // Filename is stored in the api as blob downloaded does nto contain the filename.
      type: blob.type,
      url: URL.createObjectURL(blob),
    };
  };

  const toPrefillData = async () => {
    if (content.type === CONTENT_TYPE.TEXT) {
      setPrefillData(content);
    }

    if (content.type === CONTENT_TYPE.PHOTO) {
      const file = await toFile(content.url);
      setPrefillData({
        photograph: {
          id: content?.id,
          originalPhoto: file,
          photo: file,
          source: content?.source,
          approximateDateTaken: content?.approximateDateTaken,
          filename: content?.filename,
        },
      });
    }
  };

  const setupPrefillData = async () => {
    await toPrefillData()
      .catch(() => setIsLoading(false));
  };

  const toEntityContent = async (data) => {
    switch (content.type) {
      case CONTENT_TYPE.TEXT: {
        return {
          id: data.id,
          type: CONTENT_TYPE.TEXT,
          content: data.content,
        };
      }
      case CONTENT_TYPE.PHOTO: {
        if (!hasPhotograph(data)) {
          return null;
        }

        return {
          id: data.photograph?.id,
          type: CONTENT_TYPE.PHOTO,
          url: hasContentPhotoChanged(data) ? await handleContentPhotoChange(data) : content.url,
          source: data?.photograph?.source,
          approximateDateTaken: data?.photograph?.approximateDateTaken,
          filename: data?.photograph?.photo?.name || content?.filename,
        };
      }
      default:
        return {};
    }
  };

  const removePhotoContent = async () => {
    await removeOldPhoto();
    await AxiosRequests.deleteContent(apiClient, taskId, content.id)
      .then((updatedTask) => {
        setTask(updatedTask);
        onClose();
      })
      .catch((exception) => console.error(exception.message));
  };

  const updateAdditionalContentWithEditedContent = (entityContent) => {
    const updatedContent = additionalContent.map((item) => {
      if (item.content.id === entityContent.id) {
        if (item.content.type === 'PHOTO') {
          return {
            ...item,
            timestamp: entityContent.dateTimeStamp,
            content: {
              ...item.content,
              filename: entityContent.filename,
              source: entityContent.source,
              url: entityContent.url,
              approximateDateTaken: entityContent?.approximateDateTaken,
            },
          };
        }
        return {
          ...item,
          timestamp: entityContent.dateTimeStamp,
          content: {
            ...item.content,
            content: entityContent.content,
          },
        };
      }
      return item;
    });
    return updatedContent;
  };

  const updateContent = async (entityContent) => {
    if (checkAdditionalContentItemExists(entityContent.id)) {
      setTask(updateAdditionalContent(task, entityContent, DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC)));
      setAdditionalContent(updateAdditionalContentWithEditedContent(entityContent));
      onClose();
    } else {
      await AxiosRequests.updateContent(apiClient, taskId, content.id, entityContent)
        .then((updatedTask) => {
          setTask(updatedTask);
          onClose();
        })
        .catch((exception) => console.error(exception.message));
    }
  };

  const onSubmit = async ({ data }) => {
    const entityContent = await toEntityContent(data);
    if (!entityContent) {
      await removePhotoContent();
      return;
    }
    await updateContent(entityContent);
  };

  useEffect(() => {
    if (isLoading) {
      setupPrefillData();
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    return () => setTabTitle(TAB_TITLES.TASK_DETAILS);
  }, []);

  const memoizedDeleteModal = useMemo(() => {
    return showDeleteModal ? <DetailModal onProceed={onDelete} /> : null;
  }, [showDeleteModal]);

  if (isLoading || !prefillData) {
    return <LoadingSpinner />;
  }

  return (
    <div {...props}>
      <UpliftedForm
        preFillData={prefillData}
        form={form(content.type)}
        onSubmit={onSubmit}
        onCancel={onClose}
        onChange={onChange}
        offsetTop={offsetTop}
        uploadDocument={false}
        isLoading={isLoading}
        submitModal={memoizedDeleteModal}
        overrideOnCancelConfirm
        overrideSubmit
      />
    </div>
  );
};

export default memo(EditDetail);
