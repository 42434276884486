// Global import(s)
import { BackToTop } from '@ukhomeoffice/cop-react-components';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

// Config
import config from '../../../utils/config';
import { DATE_FORMATS } from '../../../utils/constants';

// Context
import { useKeycloak } from '../../../context/Keycloak';
import { useTask } from '../../../context/TaskContext';

// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import LinkButton from '../../../components/Buttons/LinkButton';
import OfficialWarning from './components/header/OfficialWarning';
import ScanAppOutcome from './components/outcomes/ScanAppOutcome';
import TargetDetails from './components/target/TargetDetails';
import TargetHeader from './components/header/TargetHeader';
import TargetInformation from './components/movement/information/TargetInformation';
import Warnings from './components/banners/Warnings';

// Services
import AxiosRequests from '../../../api/axiosRequests';

// Hook(s)
import useRedirectIfRequired from '../../../utils/Hooks/useRedirectIfRequired';
import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';
import toPreviewSubmissionPayload from '../../Task/Uplift/TaskDetails/components/shared/target/helper/toPreviewSubmissionPayload';

// Util
import { DateTimeUtil } from '../../../utils';
import EntityUtil from '../../../utils/Entity/entityUtil';

// Styling
import './TargetSheetPage.scss';

const PreviewTargetSheetPage = ({
  backToCreateTarget,
  formData,
  mode,
  targetId,
  isSettingView,
  isSubmittedForXrayAnalysis,
  isSubmittingForXrayAnalysis,
  informationSheet,
}) => {
  const [targetSheet, setTargetSheet] = useState(null);
  const { additionalContent, taskId } = useTask();
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  if (isSubmittingForXrayAnalysis || isSettingView) {
    return <ApplicationSpinner />;
  }

  if (isSubmittedForXrayAnalysis) {
    return (<ScanAppOutcome movement={informationSheet?.movement} targetId={targetId} />);
  }

  useRedirectIfRequired(mode, targetId);

  const addContent = (data) => {
    const newData = cloneDeep(data);
    additionalContent?.forEach((addition) => {
      if (addition.type === 'PASSENGER' && addition.content.type === 'PHOTO') {
        const personsToUpdate = [newData.movement.person, ...newData.movement.otherPersons];
        personsToUpdate?.forEach((person) => {
          if (EntityUtil.isMatchingEntity(person?.entityId, addition.entityId)) {
            const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
            const user = {
              active: true,
              firstName: keycloak.tokenParsed.given_name,
              lastName: keycloak.tokenParsed.family_name,
              email: keycloak.tokenParsed.email,
            };

            if (!person?.additionalContents) {
              person.additionalContents = undefined;
            }

            person.additionalContents.unshift({
              id: addition.content.id,
              type: addition.content.type,
              url: addition.content.url,
              filename: addition.content.filename,
              source: addition.content.source,
              approximateDateTaken: addition.content?.approximateDateTaken,
              user,
              timestamp: dateTimeStamp,
              editable: true,
            });

            person.photograph = {
              approxPhotoTaken: addition.content?.approximateDateToken || null,
              uri: addition.content.url,
            };
          }
        });

        if (personsToUpdate.length > 0) {
          newData.movement.person = personsToUpdate[0];
          newData.movement.otherPersons = personsToUpdate.slice(1);
        }
      }
    });
    return newData;
  };

  const getTaskData = async () => {
    const submissionPayload = toPreviewSubmissionPayload(taskId, formData, mode);
    await AxiosRequests.previewTargetSheet(apiClient, submissionPayload)
      .then(async (data) => {
        // We can't add additionalContents (photos) here if the persons
        // don't exist - so we have to make sure they exist;
        let newData = null;
        if (data?.movement?.person && data?.movement?.otherPersons) {
          newData = addContent(JSON.parse(JSON.stringify(data)));
        }
        setTargetSheet(newData !== null && newData !== data ? newData : data);
      })
      .catch(() => {
        setTargetSheet(null);
      });
  };

  useEffect(() => {
    getTaskData();
    return () => {
      AxiosRequests.cancel(source);
    };
  }, [taskId]);

  if (!targetSheet) {
    return null;
  }

  return (
    <div id="preview-target-sheet-page" className="target-sheet-page">
      <LinkButton
        id="back-to-create-target"
        className="govuk-back-link"
        onClick={() => backToCreateTarget()}
      >
        Back to Create target
      </LinkButton>
      <OfficialWarning position="top" preview />
      <TargetHeader
        canRecordOutcome={false}
        onRecordOutcome={() => {}}
        mode={mode}
        informationSheet={targetSheet}
      />
      <Warnings informationSheet={targetSheet} />
      <TargetDetails informationSheet={targetSheet} />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <TargetInformation informationSheet={targetSheet} preview />
        </div>
      </div>
      <BackToTop />
      <OfficialWarning position="bottom" preview />
    </div>
  );
};

export default PreviewTargetSheetPage;
