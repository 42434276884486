/* eslint-disable jsx-a11y/anchor-is-valid */
// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';
import { Link } from 'react-router-dom';

// Context(s)
import { useView } from '../../../../../context/ViewContext';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import OneItemListRow from '../movement/generics/OneItemListRow';
import PreviousNext from '../movement/generics/PreviousNext';
import SummaryListRow from '../movement/generics/SummaryListRow';

// Util(s)
import TargetInformationUtil from '../../../../../utils/TargetInformation/targetInformationUtil';
import TargetPersonUtil from '../../../../../utils/Person/Target/targetPersonUtil';
import { isMobilePortrait } from '../../../../../utils/TargetSheet/targetSheetUtil';

// Styling
import './TargetDetails.scss';

const TargetDetails = ({ informationSheet }) => {
  const { width } = useWindowSize();
  const { setShowHandlingInstructions } = useView();

  const panelData = (() => {
    return [
      {
        id: 'target-category',
        label: 'Target category',
        value: informationSheet?.targetCategory || informationSheet?.risks?.selector?.category || 'C',
      },
      {
        id: 'suspected-abuse-type',
        label: 'Suspected abuse type',
        value: TargetInformationUtil.suspectedAbuseTypes(informationSheet),
      },
      {
        id: 'operation-name',
        label: 'Operation name',
        value: informationSheet.operation,
      },
      {
        id: 'reason-for-referral',
        label: 'Reason for referral',
        value: informationSheet.selectionReasoning,
      },
      {
        id: 'all-pax-to-wl',
        label: 'All pax added to border crossing?',
        value: TargetPersonUtil.allOnWhitelist(informationSheet) ? 'Yes' : 'No',
      },
      {
        id: 'public-interest-immunity',
        label: 'Public interest immunity?',
        value: informationSheet.publicInterestImmunity ? 'Yes' : 'No',
      },
    ];
  })();

  const peoplePanelData = (() => {
    return [
      {
        id: 'persons-of-interest',
        label: 'Persons of interest',
        singleLabel: 'Person of interest',
        value: TargetPersonUtil.allOfInterest(informationSheet).length,
        link: '#persons-of-interest',
      },
      {
        id: 'co-travellers',
        label: 'Co-travellers',
        singleLabel: 'Co-traveller',
        value: TargetPersonUtil.coTravellers(informationSheet).length,
        link: '#co-travellers',
      },
      {
        id: 'under-18s',
        label: 'Under 18s',
        singleLabel: 'Under 18',
        value: TargetPersonUtil.under18s(informationSheet),
        link: '#under-18s',
      },
    ];
  })();

  const areUnder18sPresent = () => {
    return peoplePanelData[2].value > 0;
  };

  return (
    <div id="target-details" className="govuk-grid-row target-info-summary">
      <div className="govuk-grid-column-full">
        <PreviousNext
          id="target-details"
          index={0}
          size={1}
        />
        <ComponentWrapper show={isMobilePortrait(width)}>
          <h2 id="target-details-header" className="ho-heading-m">Target details</h2>
        </ComponentWrapper>
        <ComponentWrapper show={!isMobilePortrait(width)}>
          <Heading id="target-details-header" size="l">Target details</Heading>
        </ComponentWrapper>
        <div id="target-details-body" className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds left-side">
            <div className="govuk-summary-list">
              {panelData
                ?.filter((item) => !!item)
                ?.map((item) => (
                  <SummaryListRow
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    mobile={isMobilePortrait(width)}
                    value={item.value}
                  />
                ))}
            </div>
          </div>
          <div className="govuk-grid-column-one-third right-side">
            <div id="people-on-this-targeting-sheet">
              <ComponentWrapper show={!isMobilePortrait(width)}>
                <Heading size="m">People on this target sheet</Heading>
              </ComponentWrapper>
              <ComponentWrapper show={isMobilePortrait(width)}>
                <h2 className="ho-heading-s">People on this target sheet</h2>
              </ComponentWrapper>
              <div className="govuk-summary-list">
                {peoplePanelData
                  ?.filter((item) => !!item)
                  ?.map((item) => (
                    <OneItemListRow
                      extraText={areUnder18sPresent() ? 'including' : null}
                      id={item.id}
                      key={item.id}
                      label={item.label}
                      singleLabel={item.singleLabel}
                      link={item.link}
                      value={item.value}
                    />
                  ))}
              </div>
            </div>
            <div id="handling-instructions">
              <Heading size="m">Handling instructions</Heading>
              <p>No further dissemination without permission of the originator.</p>
              <Link
                id="handling-instructions"
                className="govuk-link"
                style={{ padding: '0px' }}
                onClick={() => { setShowHandlingInstructions(true); }}
              >
                See more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TargetDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(TargetDetails);
